<template>
    <div>
        <div class="d-flex align-items-center mb-2">
            <h3 class="m-0 mr-5">{{$t("thesis_and_project")}}</h3>
        </div>
        <div class="w-100">
            <div class="w-100">
                <b-card bg-variant="light">
                    <div class="row" v-if="student.success === true">
                        <div class="col-md-4">
                            <h4 class="m-0">{{$t("student_information")}}</h4>
                            <ul
                                class="mt-3 m-0 p-0 list-unstyled"
                                v-if="student.success === true">
                                <li class="mb-2">
                                    <b>{{$t("name") + " " + $t("surname")}}:</b>
                                    {{student.data.full_name}}
                                </li>
                                <li>
                                    <b>{{$t("program_name")}}:</b>
                                    {{student.data.program_name}}
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-8 mt-4">
                            <ValidationObserver ref="form">
                                <ValidationProvider name="title"
                                                    rules="required"
                                                    v-slot="{ valid, errors }">
                                    <b-form-group :label="student.data.with_thesis === true ? $t('title_of_thesis'):$t('title_of_project')"
                                                  class="position-relative">
                                        <b-form-input
                                            v-model="form.title"
                                            :placeholder="student.data.with_thesis === true ? $t('title_of_thesis') : $t('title_of_project')"
                                            :state="errors[0] ? false : null">
                                        </b-form-input>
                                        <b-form-invalid-feedback
                                            v-if="errors[0]"
                                            v-html="errors[0]"
                                        ></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>

                                <ValidationProvider
                                    name="summary"
                                    rules="required"
                                    v-slot="{ valid, errors }">
                                    <b-form-group
                                        :label="student.data.with_thesis === true ? $t('summary_of_thesis') : $t('summary_of_project')"
                                        class="position-relative">
                                        <b-form-textarea
                                            v-model="form.summary"
                                            :placeholder="student.data.with_thesis === true ? $t('summary_of_thesis') : $t('summary_of_project')"
                                            :state="errors[0] ? false : null">
                                        </b-form-textarea>
                                        <b-form-invalid-feedback
                                            v-if="errors[0]"
                                            v-html="errors[0]"
                                        ></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>

                                <ValidationProvider
                                    name="advisor"
                                    rules="required"
                                    v-slot="{ valid, errors }"
                                >
                                    <b-form-group
                                        :label="student.data.with_thesis === true ? $t('advisor_of_thesis') : $t('advisor_of_project')"
                                        class="position-relative">
                                        <advisors-selectbox
                                            v-model="form.advisor_id"
                                            :type="student.data.with_thesis === true? 'thesis': 'project'"
                                            :state="errors[0] ? false : null">
                                        </advisors-selectbox>
                                        <b-form-invalid-feedback
                                            v-if="errors[0]"
                                            v-html="errors[0]"
                                        ></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>

                                <div class="d-flex mt-2">
                                    <b-button variant="primary" @click="formUpdate">{{$t("save")}}</b-button>
                                </div>
                            </ValidationObserver>
                        </div>
                    </div>
                    <div v-else>
                        <div class="spinner-border text-primary mx-auto d-flex"></div>
                    </div>
                </b-card>
            </div>
        </div>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import ThesisAndProjectService from "@/services/ThesisAndProjectService";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import AdvisorsSelectbox from "@/components/interactive-fields/AdvisorsSelectbox";

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        AppLayout,
        AdvisorsSelectbox,
    },
    metaInfo() {
        return {
            title: this.$t("thesis_and_project"),
        };
    },
    data() {
        return {
            form: {},
            student: {},
            formId: 0,
        };
    },
    created() {
        this.getStudentInfo();
    },
    methods: {
        async getStudentInfo() {
            ThesisAndProjectService.getStudentInfo().then((response) => {
                this.student = response.data;
            }).catch((e) => {
                this.$toast.error(this.$t('api.' + e.data.message))
            });
        },

        async formUpdate() {
            const isValid = await this.$refs.form.validate();
            if (isValid) {
                let formData = {
                    type: this.student.data.with_thesis === true ? "thesis" : "project",
                    ...this.form,
                };
                if (this.formId > 0) {
                    ThesisAndProjectService.update(this.formId, formData)
                        .then((response) => {
                            this.$toast.success(this.$t("api." + response.data.message));
                        })
                        .catch((e) => {
                            this.showErrors(e);
                        });
                }
                else {
                    ThesisAndProjectService.store(formData)
                        .then((response) => {
                            this.$toast.success(this.$t("api." + response.data.message));
                            this.formId = response.data.data.id;
                        })
                        .catch((e) => {
                            this.showErrors(e);
                        });
                }

            }
        },

        showErrors(e) {
            if (e.status == "422") {
            }
            else if (e.status == "406") {
                this.$toast.error(this.$t("api." + e.data.message));
            }
            else if (e.status == "401") {
                this.$toast.error(this.$t("api." + e.data.message));
            }
        },
    },
};
</script>



